import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NewsletterThanksPage = () => (
  <Layout>
    <SEO title="Newsletter Thanks" />
    <h1 className="title">Thanks for subscribing to our newsletter</h1>
    <hr />
    <p>We promise not to spam you, as we understand how annoying this can be!</p>
  </Layout>
);

export default NewsletterThanksPage;
